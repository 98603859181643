<template>
  <div>
    <!-- downloadD7D4F945FC0CD792F87B6FDDAC310E93.swf -->
    <!-- pic1CA14D99B9D871E5401332ED695AE17D.jpg -->
    <img class="img-large rounded" src="">
    <p>
      A robot could come in handy as you adventure around LEGO Universe, so get out your ratchets, wrenches and blowtorches to bolt one together! Extra inspiration points go to anyone who shows off a SNOT technique. The <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '531ADA8F-3EE8-46BF-9D70-6EDD112E1CBE'} }">Build a SNOT bot challenge</router-link> is live in the Creation Lab!
    </p>
  </div>
</template>
